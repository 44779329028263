import React from 'react';

const SocketSvg = ({ shapeInfo, outline, drawOutline, handleClick, index, hiddenIndex, isDrawInEdit }: any) => {
  const r =
    shapeInfo.smallRadius + 2 + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle + shapeInfo?.paddingBigCircle;

  if (drawOutline) {
    return (
      <>
        {hiddenIndex === -1 && index === 0 && isDrawInEdit ? (
          <circle
            id={index}
            onClick={handleClick}
            cx={shapeInfo?.startX}
            cy={shapeInfo?.startY}
            r={
              shapeInfo.smallRadius + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle + 1.3
              // +
              // shapeInfo?.paddingBigCircle
            }
            stroke="black"
            strokeWidth={5}
            fill="none"
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
          />
        ) : (
          <></>
        )}
        <circle
          id={index}
          onClick={handleClick}
          cx={shapeInfo?.startX}
          cy={shapeInfo?.startY}
          r={
            shapeInfo.smallRadius +
            shapeInfo.borderSmallWidth +
            shapeInfo.paddingSmallCircle +
            (hiddenIndex === -1 && index === 0 && isDrawInEdit ? -1.1 : 0)
            // +
            // shapeInfo?.paddingBigCircle
          }
          stroke="grey"
          strokeWidth={hiddenIndex === -1 && index === 0 && isDrawInEdit ? 7.8 : 5}
          fill={hiddenIndex === -1 && index === 0 && isDrawInEdit ? 'none' : 'black'}
          transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
        />
      </>
    );
  } else {
    return (
      <g
        id={index}
        onClick={handleClick}
        transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
      >
        <image
          x={shapeInfo?.startX + (shapeInfo?.outlinePadding ?? 0) - r - 10}
          y={shapeInfo?.startY + (shapeInfo?.outlinePadding ?? 0) - r - 10}
          width={r * 2 + 20}
          height={r * 2 + 20}
          preserveAspectRatio="none"
        />
        <circle
          cx={shapeInfo?.startX}
          cy={shapeInfo?.startY}
          r={shapeInfo.smallRadius + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle}
          fill="none"
        />
      </g>
    );
  }
};

export default SocketSvg;

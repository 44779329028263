import { cloneDeep } from 'lodash';
import { applyCalculationAfterTransform, getCorrectRotationRadian } from '../utils';

var offset = {
  x: 0,
  y: 0,
};
var transform: any = 0;
var lastPosition = {
  x: 0,
  y: 0,
};
var startPosition = {
  x: 0,
  y: 0,
};

var isRotate = false;
var rotationCenter: any = null;
var rotation: any = null;

var svgShape: any = null;

var rootRotation: any = {
  x: 0,
  y: 0,
};

var rootAngle: any = 0;

var pathDelete: any = [];
var isAddDeletePath = false;
var isAddRestorePath = false;
var pathIndex: any = 0;
var cursorSizeData: any = 20;
let selectedElement: any;

var isDownAndMoveForDelete = false;

export const handleIsDownAndMoveForDelete = (val: boolean) => {
  isDownAndMoveForDelete = val;
};

export const setOffset = ({ x, y }: any) => {
  offset = {
    x: 0,
    y: 0,
  };
};

export const setRotate = (evt: any, extraState: any, rotate: any, dataMoveable: any) => {
  isRotate = rotate;
  var coord = getMousePosition(evt);
  rootRotation = coord;
  rootAngle = dataMoveable?.rotate?.a;
};

export const setTransform = (newTransform: any) => {
  transform = newTransform;
};

export const resetPathDelete = (e: any) => {
  pathDelete = [];
};

export const getPathDelete = (e: any) => {
  let output = pathDelete;
  return output;
};

export const addPathDelete = (pathOffset: any) => {
  pathDelete.push({
    x: pathOffset?.x?.toFixed(1),
    y: pathOffset?.y?.toFixed(1),
  });
};

export const startAddPathDelete = (pathDeleteIndex: any) => {
  isAddRestorePath = false;
  isAddDeletePath = true;
  pathDelete = [];
  pathIndex = pathDeleteIndex;
};

export const startAddPathRestore = (pathRestoreIndex: any) => {
  isAddDeletePath = false;
  isAddRestorePath = true;
  pathDelete = [];
  pathIndex = pathRestoreIndex;
};

export const endAddPathDelete = (e: any) => {
  isAddDeletePath = false;
  isAddRestorePath = false;
  let output = cloneDeep(pathDelete);
  pathDelete = [];

  return output;
};

export const makeDraggable = (index: any, extraState: any) => {
  svgShape = document.getElementById('Layer_1');
  // extraState.svgShapeEditing = svgShape;
};

export const endDrag = ({ evt, onDragEnd, onRotateEnd, index, extraState, dataMoveable }: any) => {
  if (isRotate && dataMoveable?.rotate && rotationCenter) {
    onRotateEnd({
      index,
      a: dataMoveable?.rotate?.a,
      x: dataMoveable?.rotate?.x,
      y: dataMoveable?.rotate?.y,
      baseScale: dataMoveable?.rotate?.baseScale,
    });
    isRotate = false;
  } else {
    if (lastPosition.x === 0 && lastPosition.y === 0) {
      onDragEnd({
        index,
        x: 0,
        y: 0,
      });
      if (transform) {
        transform?.setTranslate(0, 0);
      }
    } else {
      onDragEnd({
        index,
        x: lastPosition.x - startPosition.x,
        y: lastPosition.y - startPosition.y,
      });
      if (transform) {
        transform?.setTranslate(0, 0);
      }
    }

    startPosition.x = lastPosition.x;
    startPosition.y = lastPosition.y;
    // if (extraState?.lastPositionDrag) {
    //   extraState.startPositionDrag = extraState.lastPositionDrag;
    // }
  }

  isRotate = false;
};

export const startDrag = ({ evt, index, handleClick, dataMoveable }: any) => {
  //Added to prevent case when you haven't initiate svgShape
  if (!svgShape) {
    svgShape = document.getElementById('Layer_1');
    // extraState.svgShapeEditing = svgShape;
  }
  // console.log({evt, extraState});
  selectedElement = document.getElementById(`current_drag_${index}`);

  lastPosition.x = 0;
  lastPosition.y = 0;

  if (selectedElement && lastPosition.x === 0 && lastPosition.y === 0) {
    offset = getMousePosition(evt);
    startPosition = offset;

    var transforms = selectedElement?.transform.baseVal;

    if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
      var translate = svgShape?.createSVGTransform();
      translate?.setTranslate(0, 0);
      selectedElement?.transform?.baseVal?.insertItemBefore(translate, 0);
    }
    transform = transforms.getItem(0);

    offset.x -= transform.matrix.e;
    offset.y -= transform.matrix.f;

    // extraState.offsetEditing = {
    //   x: extraState.offsetEditing.x - transform.matrix.e,
    //   y: extraState.offsetEditing.y - transform.matrix.f,
    // };
  } else {
    if (selectedElement) {
      offset = getMousePosition(evt);
      // extraState.offsetEditing = offset;
      //this use to fix a case when you instanly click cancel select
      lastPosition.x = 0 + offset.x;
      lastPosition.y = 0 + offset.y;
      // extraState.lastPositionDrag = {
      //   x: 0 + offset.x,
      //   y: 0 + offset.y,
      // };
    }
  }

  rotationCenter = applyCalculationAfterTransform({
    transform: dataMoveable?.transform,
    rotate: dataMoveable?.rotate,
    scale: dataMoveable?.scale,
    centerPoint: { x: dataMoveable?.rotate?.x, y: dataMoveable?.rotate?.y },
  });

  // extraState.currentCenter = rotationCenter;
};

export const drag = ({
  evt,
  index,
  handleClick,
  dataMoveable,
  onRotateEnd,
  extraState,
  onDragEnd,
  isDownAndMove,
}: any) => {
  let selectedElement: any = null;
  if (!selectedElement) {
    selectedElement = document.getElementById(`current_drag_${index}`);
  }

  var coord = getMousePosition(evt);
  if (isRotate) {
    let rotation = getCorrectRotationRadian({
      coord,
      rootRotation,
      rotationCenter,
      rootAngle,
    });

    onRotateEnd({
      index,
      a: rotation,
      x: dataMoveable?.rotate?.x,
      y: dataMoveable?.rotate?.y,
      baseScale: dataMoveable?.rotate?.baseScale,
    });
  } else {
    if (isDownAndMove && selectedElement) {
      var currentBBox = selectedElement?.getBBox();
      var newBBox = {
        x: lastPosition.x == 0 ? currentBBox?.x : lastPosition.x - startPosition.x + currentBBox?.x,
        y: lastPosition.y == 0 ? currentBBox?.y : lastPosition.y - startPosition.y + currentBBox?.y,
      };

      if (
        coord.x < newBBox?.x ||
        coord.y < newBBox?.y ||
        coord.x > newBBox?.x + currentBBox?.width ||
        coord.y > newBBox?.y + currentBBox?.height
      ) {
        handleClick(-1);
      } else {
        if (transform != 0) {
          transform?.setTranslate(coord.x - offset.x, coord.y - offset.y);
          lastPosition.x = coord.x;
          lastPosition.y = coord.y;
        }
      }
    }
  }
};

export const getMousePosition = (evt: any) => {
  let checkSvg: any = document.getElementById('Layer_1');
  let CTM = checkSvg?.getScreenCTM();

  if (evt.touches) {
    evt = evt.touches[0];
  }
  return {
    x: (evt.clientX - CTM?.e) / CTM?.a,
    y: (evt.clientY - CTM?.f) / CTM?.d,
  };
};

export const startDeletePath = ({
  evt,
  index,
  handleClick,
  dataMoveable,
  extraState,
  onChangeCursorPosition,
  cursorSize,
}: any) => {
  offset = getMousePosition(evt);
  if (evt) {
    onChangeCursorPosition({
      x: evt?.clientX.toFixed(2),
      y: evt?.clientY.toFixed(2),
    });
  }
  cursorSizeData = cursorSize;
  pathDelete.push({
    x: offset?.x?.toFixed(1),
    y: offset?.y?.toFixed(1),
  });
};

export const dragDelete = ({ evt, extraState, onDragDeleteEnd, onChangeCursorPosition }: any) => {
  if (isDownAndMoveForDelete) {
    let coord = getMousePosition(evt);
    pathDelete.push({ x: coord?.x?.toFixed(1), y: coord?.y?.toFixed(1) });
    //optimize path here:
    if (typeof onChangeCursorPosition == 'function') {
      if (evt) {
        onChangeCursorPosition({
          x: evt?.clientX.toFixed(2),
          y: evt?.clientY.toFixed(2),
        });
      }
    }
    onDragDeleteEnd({
      newPoint: {x: coord?.x?.toFixed(1), y: coord?.y?.toFixed(1)},
      pathIndex: pathIndex,
      path: pathDelete,
      type: isAddDeletePath ? 'delete' : isAddRestorePath ? 'restore' : 'unknow',
      size: cursorSizeData,
    });
  }
};

export const endDragDelete = ({ evt, onDragDeleteEnd, onChangeCursorPosition }: any) => {
  if (typeof onChangeCursorPosition == 'function') {
    if (evt) {
      onChangeCursorPosition({
        x: evt?.clientX.toFixed(2),
        y: evt?.clientY.toFixed(2),
      });
    }
  }
  onDragDeleteEnd({
    pathIndex: pathIndex,
    path: pathDelete,
    type: isAddDeletePath ? 'delete' : isAddRestorePath ? 'restore' : 'unknow',
    size: cursorSizeData,
  });
  pathIndex += 1;
  pathDelete = [];
};

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import Loading from 'src/components/common/loading';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message, Form, Input, Upload, Select, Slider } from 'antd';
import NotFoundPage from 'src/pages/notFoundPage';
import IconDelete from 'src/assets/icon/IconDelete';
import IconAddForm from 'src/assets/icon/IconAddForm';
import { useMutation, useQueryClient } from 'react-query';
import { useDetailPart } from 'src/api/useDetailPart';
import { PartType, useAddPart } from 'src/api/useAddPart';
import { PartEditType, useEditPart } from 'src/api/useEditPart';
import { useListPartCategories } from 'src/api/useListPartCategories';
import { TypeCategoryPart } from 'src/assets/constants/enums';
import { NumericFormat } from 'react-number-format';
import { AnyPtrRecord } from 'dns';
import IconSubForm from 'src/assets/icon/IconSubForm';
import IconUpload from 'src/assets/icon/IconUpload';
import { useFetchParts } from 'src/api/useFetchParts';
import stylesAddPanel from 'src/components/features/superAdmin/EditorComponent/AddPanel/index.module.scss';
import stylesAddSocket from 'src/components/features/superAdmin/EditorComponent/AddSocket/index.module.scss';
import {
  ACRYLIC_WIDTH_MAX,
  ACRYLIC_WIDTH_MIN,
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  PANEL_RANGE_SLIDER,
  RANGE_SLIDER_STEP_SIZE,
  WIDTH_PANEL_STAMP_ITEM_LINE,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import convert from 'convert-length';
import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import { clamp, cropVisibleArea, getElementSvgFile } from 'src/utils';
import { calculateSvgSize, revertCalculationBeforeTransform } from './EditorComponent/SvgMerge/utils';
import SvgMerge from './EditorComponent/SvgMerge';
import MenuBottom from 'src/components/common/menuBottom';
import { MenuStep2AddPanel } from './EditorComponent/SvgMerge/menu';
import { SOCKET_LIST } from './EditorComponent/AddSocket/constants/socketList';

const PartCreateEditComponent = () => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [imageUrlArr, setImageUrlArr] = useState<Array<any>>(['']);
  const [isAddPedestal, setIsAddPedestal] = useState(false);
  const [imageMessError, setImageMessError] = useState<string>('');
  const [imageFile, setImageFile] = useState<Array<any>>([{}]);
  const [ratios, setImageFileRatios] = useState<Array<any>>(['']);
  const [memos, setImageFileMemos] = useState<Array<any>>(['']);
  const [partRemoveArr, setPartRemoveArr] = useState<Array<any>>([]);
  const [imageMessErrorArr, setImageMessErrorArr] = useState<Array<any>>(['']);
  const namePartCurrent = Form.useWatch('name', antForm);
  const categoryCurrent = Form.useWatch('part_category_id', antForm);
  const partsCurrent = Form.useWatch('parts', antForm);
  const queryClient = useQueryClient();
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailPart(Number(id));
  const { data: dataPartCategories } = useListPartCategories();
  const dataSelectCategories = dataPartCategories?.data?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.name,
      type: item?.type,
    };
  });
  const typeCategoryPartCurrent = dataSelectCategories?.find((item: any) => item?.value === categoryCurrent)?.type;
  const isHasSizeCategoryPart = typeCategoryPartCurrent === TypeCategoryPart.HAS_SIZE;

  const handleGoBack = () => {
    history(id ? `/product/parts/${id}` : '/product/parts');
  };

  const { isLoading: isLoadingPart, mutate: onCreatePart } = useMutation((param: PartType) => useAddPart(param), {
    onSuccess: () => {
      message.success('カテゴリー登録に成功しました');
      history('/product/parts');
      antForm.resetFields();
    },
    onError: () => {
      message.error('システムにエラーが発生しました。後でもう一度お試しください');
    },
  });

  const { isLoading: isLoadingEdit, mutate: onEditPart } = useMutation(
    (param?: PartEditType) => useEditPart(param, Number(id)),
    {
      onSuccess: () => {
        message.success('カテゴリー編集に成功しました ');
        history(`/product/parts`);
        antForm.resetFields();
        queryClient.clear();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const handleCreateEditPart = async () => {
    const valueForm = antForm.getFieldsValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    if (!onCheckDisableBtnSubmit()) {
      if (id) {
        if (isHasSizeCategoryPart) {
          const newPartListEditting = valueForm?.parts?.map((item: any, index: any) => {
            let newItemPart = item;
            if (Object.keys(imageFile?.[index])?.length !== 0) {
              return {
                ...item,
                images: imageFile?.[index],
              };
            }
            return newItemPart;
          });
          onEditPart({
            name: valueForm?.name,
            part_category_id: valueForm?.part_category_id,
            parts: newPartListEditting,
            delete_ids: partRemoveArr,
          });
        } else {
          const newParts = valueForm?.parts?.filter(
            (item: any) => !partRemoveArr?.find((itemRemoved: any) => itemRemoved === item?.id)
          );
          const newPartsNotSize = newParts?.map((item: any, index: any) => {
            let newItemPart = item;
            if ((!!imageFile?.[index] && Object.keys(imageFile?.[index])?.length !== 0) || categoryCurrent === 5) {
              if (categoryCurrent === 5) {
                return {
                  ...item,
                  ratio_socket_panel: Number(ratios?.[index]),
                  memo: memos?.[index],
                  images: imageFile?.[index],
                };
              }
              return {
                ...item,
                images: imageFile?.[index],
              };
            }
            return newItemPart;
          });
          onEditPart({
            name: valueForm?.name,
            parts: newPartsNotSize,
            part_category_id: valueForm?.part_category_id,
            delete_ids: partRemoveArr,
          });
        }
      } else {
        if (isHasSizeCategoryPart) {
          const newPartList = valueForm?.parts?.map((item: any, index: any) => {
            return {
              ...item,
              images: imageFile?.[index],
            };
          });
          onCreatePart({ name: valueForm?.name, parts: newPartList, part_category_id: valueForm?.part_category_id });
        } else {
          const newPartsNotSize = imageFile?.map((item: any, index: number) => {
            if (categoryCurrent === 5) {
              return {
                images: item,
                ratio_socket_panel: Number(ratios?.[index]),
                memo: memos?.[index],
              };
            }
            return {
              images: item,
            };
          });
          onCreatePart({
            name: valueForm?.name,
            parts: newPartsNotSize,
            part_category_id: valueForm?.part_category_id,
          });
        }
      }
    }
  };

  const onEditImageUrlArr = (url?: any, indexImageEditing?: any) => {
    const newImageUrlArr = imageUrlArr?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return url;
      }
      return item;
    });
    setImageUrlArr(indexImageEditing === imageUrlArr?.length - 1 ? [...newImageUrlArr, ''] : newImageUrlArr);
    const newImagePartForm =
      indexImageEditing === imageUrlArr?.length - 1
        ? [...(partsCurrent || []), { images: url }]
        : partsCurrent?.map((item: any, indexPart: any) => {
            if (indexPart === indexImageEditing) {
              return {
                ...item,
                images: url,
              };
            }
            return item;
          });
    antForm.setFieldsValue({ parts: newImagePartForm });
  };

  const onEditImageFileRatioArr = (ratio_socket_panel: any, indexImageEditing: any) => {
    let newImageFileArr: any;
    if (indexImageEditing > ratios?.length - 1) {
      newImageFileArr = [...ratios, ratio_socket_panel];
    } else {
      newImageFileArr = ratios?.map((item: any, indexUrl: any) => {
        if (indexImageEditing === indexUrl) {
          return ratio_socket_panel;
        }
        return item;
      });
    }
    setImageFileRatios(newImageFileArr);
  };

  const onEditImageFileMemoArr = (memo: any, indexImageEditing: any) => {
    let newImageFileArr: any;
    if (indexImageEditing > memos?.length - 1) {
      newImageFileArr = [...memos, memo];
    } else {
      newImageFileArr = memos?.map((item: any, indexUrl: any) => {
        if (indexImageEditing === indexUrl) {
          return memo;
        }
        return item;
      });
    }
    setImageFileMemos(newImageFileArr);
  };

  const onEditImageFileArr = (file: File, indexImageEditing: any) => {
    let newImageFileArr: any;
    if (indexImageEditing > imageFile?.length - 1) {
      newImageFileArr = [...imageFile, file];
    } else {
      newImageFileArr = imageFile?.map((item: any, indexUrl: any) => {
        if (indexImageEditing === indexUrl) {
          return file;
        }
        return item;
      });
    }
    setImageFile(newImageFileArr);
  };

  const beforeUpload = async (file: any, index: any, categoryCurrent: any, ratio_socket_panel?: any, memo?: any) => {
    const isLt5MB = file.size / 1024 / 1024 < 5;

    let allowedFileTypes = '';
    if (categoryCurrent === 1) {
      allowedFileTypes = 'svg|png';
    } else if (categoryCurrent === 4) {
      allowedFileTypes = 'svg';
    }

    if (RegExp(`(${allowedFileTypes})`, 'i').test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        setImageMessError('ファイルサイズが5MBを超えています');
        return;
      } else {
        const url = window.URL.createObjectURL(file);
        onEditImageUrlArr(url, index);
        onEditImageFileArr(file, index);
        onEditImageFileRatioArr(ratio_socket_panel, index);
        onEditImageFileMemoArr(memo, index);
        setImageMessError('');
      }
    } else {
      setImageMessError('アップロードするファイルは「SVG」と「PNG」のいずれかの形式にしてください');
      return;
    }
    return false;
  };

  const validateTitle = (rule: any, value: string, callback: any) => {
    if (!value?.trim()) {
      callback(`サブカテゴリー名を入力してください`);
    } else if (value?.trim()?.length > 50) {
      callback(`サブカテゴリー名は50文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const onCheckDisableBtnSubmit = () => {
    const hasNoImage = !imageUrlArr?.filter((item: any) => !!item)?.length;
    const validateErrorValueList = hasNoImage || !namePartCurrent || !!imageMessError;
    return validateErrorValueList;
  };

  const onCheckDisableBtnSubmitPartHasSize = () => {
    let isDisableBtnSubmitCurrent = false;
    partsCurrent?.forEach((item: any) => {
      if (Number(item?.height || 0) === 0 || !item?.memo || Number(item?.width || 0) === 0) {
        isDisableBtnSubmitCurrent = true;
      }
    });
    const hasNoImage = !!imageUrlArr?.filter((item: any) => !item)?.length;
    const hasErrorImage = !!imageMessErrorArr?.filter((item: any) => item)?.length;
    const validateErrorValueList =
      isDisableBtnSubmitCurrent || !namePartCurrent || hasNoImage || (hasErrorImage && hasNoImage);
    return validateErrorValueList;
  };

  const onEditImageMessErrorArr = (error?: any, indexImageEditing?: any) => {
    const newImageMessErrorArr = imageMessErrorArr?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return error;
      }
      return item;
    });
    setImageMessErrorArr(newImageMessErrorArr);
  };

  const validateMemo = (rule: any, value: string, callback: any, indexPlate: any) => {
    if (!value?.trim()) {
      callback(`メモを入力してください`);
    } else if (value?.trim()?.length > 10) {
      callback(`メモは10文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const onEditImagePartHasSizeUrlArr = (url?: any, indexImageEditing?: any) => {
    const newImageUrlArr = imageUrlArr?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return url;
      }
      return item;
    });
    setImageUrlArr(newImageUrlArr);
    const newImagePartForm = partsCurrent?.map((item: any, indexPlate: any) => {
      if (indexPlate === indexImageEditing) {
        return {
          ...item,
          images: url,
        };
      }
      return item;
    });
    antForm.setFieldsValue({ parts: newImagePartForm });
  };

  const onEditImageFilePartHasSizeArr = (file: File, indexImageEditing: any) => {
    const newImageFileArr = imageFile?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return file;
      }
      return item;
    });
    setImageFile(newImageFileArr);
  };

  const beforeUploadImagrPartHasSize = async (file: any, index: any) => {
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (RegExp(/(svg)/i).test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        onEditImageMessErrorArr('ファイルサイズが5MBを超えています', index);
        return;
      } else {
        const url = window.URL.createObjectURL(file);
        onEditImagePartHasSizeUrlArr(url, index);
        onEditImageFilePartHasSizeArr(file, index);
        onEditImageMessErrorArr('', index);
      }
    } else {
      onEditImageMessErrorArr('アップロードするファイルは「SVG」のいずれかの形式にしてください', index);
      return;
    }
    return false;
  };

  const handleDeleteThumbnail = (index: AnyPtrRecord) => {
    onEditImagePartHasSizeUrlArr('', index);
    onEditImageMessErrorArr('ファイルをアップロードしてください', index);
  };

  const uploadButton = (
    <button
      className="btn-add-part"
      type="button"
      onClick={() => {
        if (categoryCurrent === 5) {
          setIsAddPedestal(true);
        }
      }}
    >
      <IconAddForm />
    </button>
  );

  const uploadImagePartHasSizeButton = (
    <button className="upload-file" type="button">
      <IconUpload />
      <p className="text-upload">ファイルアップロード</p>
    </button>
  );

  const validateNumber = (rule: any, value: string, callback: any, errorNotValue: any) => {
    if (!String(value)?.trim()) {
      callback(errorNotValue);
    } else if (Number(String(value)?.trim()) === 0) {
      callback(`0以外の値を入力してください`);
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (detailData) {
      const typeCategoryPartDetail = dataSelectCategories?.find(
        (item: any) => item?.value === detailData?.part_category_id
      )?.type;
      const isHasSizeCategoryPartDetail = typeCategoryPartDetail === TypeCategoryPart.HAS_SIZE;
      const newImageUrlArr = detailData?.parts?.map((item: any) => {
        return item?.file_path;
      });
      if (isHasSizeCategoryPartDetail) {
        setImageUrlArr([...newImageUrlArr]);
      } else {
        setImageUrlArr([...newImageUrlArr, '']);
      }
      const lengthListPartFile = detailData?.parts?.length;

      if (lengthListPartFile) {
        const newArrImageFileFill = new Array(lengthListPartFile).fill({});
        const newArrImageFileRatiosFill = new Array(lengthListPartFile).fill('');
        const newArrImageErrorFill = new Array(lengthListPartFile).fill('');
        setImageMessErrorArr(newArrImageErrorFill);
        setImageFile(newArrImageFileFill);
        setImageFileRatios(detailData?.parts?.map((item: any) => item?.ratio_socket_panel));
        setImageFileMemos(detailData?.parts?.map((item: any) => item?.memo));
      }
      // console.log({detailData})
      antForm.setFieldsValue(detailData);
    }
  }, [detailData, isHasSizeCategoryPart]);

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail && id) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">{id ? 'カテゴリーの編集' : 'カテゴリーの追加'}</span>
            </div>

            <div></div>
          </div>
          <Form form={antForm} className="form-create w-[900px] mx-auto" layout="vertical" autoComplete="off">
            <div className="pb-[12px]">
              <div className={`mb-[16px] ${isHasSizeCategoryPart ? 'mr-[54px]' : ''}`}>
                <Form.Item required label="カテゴリー名" name="part_category_id" rules={[{ required: true }]}>
                  <Select
                    className={`select-type-part ${!!id ? 'select-part-disable' : ''}`}
                    options={dataSelectCategories}
                    placeholder="カテゴリー名を選択してください"
                    onChange={() => {
                      setImageUrlArr(['']);
                      setImageMessError('');
                      setImageFile([{}]);
                      setImageFileRatios(['']);
                      setImageFileMemos(['']);
                      setPartRemoveArr([]);
                      setImageMessErrorArr(['']);
                      antForm.resetFields(['parts']);
                      setIsAddPedestal(false);
                    }}
                    disabled={!!id}
                  />
                </Form.Item>
              </div>
              <div className={`mb-[16px] ${isHasSizeCategoryPart ? 'mr-[54px]' : ''}`}>
                <Form.Item
                  required
                  label="サブカテゴリー名"
                  name="name"
                  rules={[{ validator: (rule, value, callback) => validateTitle(rule, value, callback) }]}
                >
                  <Input className="input-form" placeholder="サブカテゴリー名を入力してください" />
                </Form.Item>
              </div>

              {isHasSizeCategoryPart ? (
                <Form.List name="parts" initialValue={[{}]}>
                  {(partList, { add, remove }) => (
                    <div className="">
                      {partList?.map((item: any, index: any) => {
                        return (
                          <div className="view-form-info-plate" key={item?.key}>
                            <div className="mt-[30px] bg-blue p-[10px] pb-[30px]">
                              <div className="mb-[30px]">
                                <Form.Item required label="画像のサムネイル" name={[item?.name, 'images']}>
                                  <div className="view-img-crop view-img-crop-part">
                                    <Upload
                                      listType="picture-card"
                                      showUploadList={false}
                                      multiple={false}
                                      maxCount={1}
                                      beforeUpload={(file) => beforeUploadImagrPartHasSize(file, index)}
                                      className="upload-file-image upload-file-part-has-size"
                                    >
                                      {imageUrlArr?.[index] ? (
                                        <img src={imageUrlArr?.[index]} alt="thumbnail" className="thumbnail-upload" />
                                      ) : (
                                        uploadImagePartHasSizeButton
                                      )}
                                    </Upload>
                                    {imageUrlArr?.[index] && (
                                      <button
                                        className="icon-delete-plate"
                                        onClick={() => handleDeleteThumbnail(index)}
                                        type="button"
                                      >
                                        <IconDelete />
                                      </button>
                                    )}
                                  </div>
                                </Form.Item>
                                {imageMessErrorArr?.[index] && (
                                  <p className="invalid-feedback whitespace-pre-line">{imageMessErrorArr?.[index]}</p>
                                )}
                              </div>
                              <div className="mb-[50px] flex items-top gap-[30px]">
                                <Form.Item
                                  required
                                  label="高さ"
                                  name={[item?.name, 'height']}
                                  className="w-[257px] error-form-acrylic"
                                  rules={[
                                    {
                                      validator: (rule, value, callback) =>
                                        validateNumber(rule, value, callback, `高さを入力してください`),
                                    },
                                  ]}
                                >
                                  <NumericFormat
                                    pattern="[0-9]*"
                                    className="number-input"
                                    style={{ width: '100%' }}
                                    allowNegative={false}
                                    decimalScale={0}
                                    placeholder="例：20"
                                    maxLength={10}
                                  />
                                </Form.Item>
                                <Form.Item
                                  required
                                  label="幅"
                                  name={[item?.name, 'width']}
                                  className="w-[257px] error-form-acrylic"
                                  rules={[
                                    {
                                      validator: (rule, value, callback) =>
                                        validateNumber(rule, value, callback, `幅を入力してください`),
                                    },
                                  ]}
                                >
                                  <NumericFormat
                                    pattern="[0-9]*"
                                    className="number-input"
                                    style={{ width: '100%' }}
                                    allowNegative={false}
                                    decimalScale={0}
                                    placeholder="例：20"
                                    maxLength={10}
                                  />
                                </Form.Item>
                                <div className="w-[257px]" />
                              </div>
                              <Form.Item
                                required
                                label="メモ"
                                name={[item?.name, 'memo']}
                                rules={[
                                  {
                                    validator: (rule, value, callback) => validateMemo(rule, value, callback, index),
                                  },
                                ]}
                                className="w-[257px] error-form-acrylic"
                              >
                                <Input className="input-form" placeholder="例：内径３ｍｍ" />
                              </Form.Item>
                            </div>
                            <div className="flex flex-col w-[42px] ml-[16px]">
                              {(index > 0 || (index === 0 && partList?.length > 1)) && (
                                <div
                                  className="button-add-item-form"
                                  onClick={(e: any) => {
                                    if (partsCurrent?.[index]?.id) {
                                      setPartRemoveArr([...partRemoveArr, partsCurrent?.[index]?.id]);
                                    }
                                    remove(item?.name);
                                    setImageUrlArr(
                                      imageUrlArr?.filter((item: any, indexFilter: any) => indexFilter !== index)
                                    );
                                    setImageFile(
                                      imageFile?.filter((item: any, indexFilter: any) => indexFilter !== index)
                                    );
                                    setImageMessErrorArr(
                                      imageMessErrorArr?.filter((item: any, indexFilter: any) => indexFilter !== index)
                                    );
                                  }}
                                >
                                  <IconSubForm />
                                </div>
                              )}
                              {(index === partList?.length - 1 || (index === 0 && partList?.length === 1)) && (
                                <div
                                  className={`button-add-item-form ${index > 0 ? 'mt-[40px]' : ''}`}
                                  onClick={(e: any) => {
                                    add(item?.name);
                                    setImageUrlArr([...imageUrlArr, '']);
                                    setImageFile([...imageFile, {}]);
                                    setImageFileRatios([...ratios, '']);
                                    setImageFileMemos([...memos, '']);
                                    setImageMessErrorArr([...imageMessErrorArr, '']);
                                  }}
                                >
                                  <IconAddForm />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Form.List>
              ) : (
                typeCategoryPartCurrent && (
                  <div className="mt-[30px] bg-blue p-[10px] w-full">
                    <Form.Item className="file-part-form" required label="カテゴリー画像" name="parts"></Form.Item>
                    <div
                      className={`${imageUrlArr?.length > 1 && !isAddPedestal ? 'flex flex-wrap mt-[30px]' : 'mt-0'}`}
                    >
                      {categoryCurrent === 5 && isAddPedestal ? (
                        <AddPedestal
                          setIsAddPedestal={setIsAddPedestal}
                          beforeUpload={beforeUpload}
                          index={imageUrlArr.length - 1}
                          categoryCurrent={categoryCurrent}
                        />
                      ) : (
                        <>
                          {imageUrlArr?.map((item: any, index: any) => {
                            return (
                              <div
                                className={
                                  !!item
                                    ? `view-img-crop view-img-crop-part mb-[40px] ${
                                        index !== 0 && index % 4 === 3 ? '' : 'mr-[26px]'
                                      }`
                                    : 'view-img-crop-none-file'
                                }
                                key={index}
                              >
                                {categoryCurrent === 5 ? (
                                  <div
                                    className={
                                      index !== imageUrlArr?.length - 1
                                        ? 'upload-file-type-5'
                                        : 'upload-part-type-5-none-file'
                                    }
                                  >
                                    {item ? (
                                      <img
                                        src={item}
                                        alt="thumbnail"
                                        className="thumbnail-upload"
                                        style={{ objectFit: 'contain' }}
                                      />
                                    ) : (
                                      uploadButton
                                    )}
                                  </div>
                                ) : (
                                  <Upload
                                    listType="picture-card"
                                    showUploadList={false}
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={(file) => beforeUpload(file, index, categoryCurrent)}
                                    className={
                                      index !== imageUrlArr?.length - 1 ? 'upload-file-part' : 'upload-part-none-file'
                                    }
                                  >
                                    {item ? (
                                      <img src={item} alt="thumbnail" className="thumbnail-upload" />
                                    ) : (
                                      uploadButton
                                    )}
                                  </Upload>
                                )}
                                {item && (
                                  <button
                                    className="icon-delete-plate"
                                    onClick={() => {
                                      if (partsCurrent?.[index]?.id) {
                                        setPartRemoveArr([...partRemoveArr, partsCurrent?.[index]?.id]);
                                      }
                                      if (imageUrlArr?.length === 2) {
                                        setImageMessError('ファイルをアップロードしてください');
                                      }
                                      setImageUrlArr(
                                        imageUrlArr?.filter((item: any, indexFilter: any) => indexFilter !== index)
                                      );
                                      const newPartsCurrent = partsCurrent?.filter(
                                        (item: any, indexFilter: any) => indexFilter !== index
                                      );
                                      antForm.setFieldsValue({ parts: newPartsCurrent });
                                      setImageFile(
                                        imageFile?.filter((item: any, indexFilter: any) => indexFilter !== index)
                                      );
                                      setImageFileRatios((prevRatios) => prevRatios.filter((_, i) => i !== index));
                                      setImageFileMemos((prevMemos) => prevMemos.filter((_, i) => i !== index));
                                    }}
                                    type="button"
                                  >
                                    <IconDelete />
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>

                    {imageMessError && <p className="invalid-feedback whitespace-pre-line">{imageMessError}</p>}
                  </div>
                )
              )}
            </div>
            <div className={`footer-form flex ${isHasSizeCategoryPart ? 'mr-[54px]' : ''}`}>
              <Button className="button-form mr-[16px]" onClick={handleGoBack}>
                キャンセル
              </Button>
              <Button
                disabled={isHasSizeCategoryPart ? onCheckDisableBtnSubmitPartHasSize() : onCheckDisableBtnSubmit()}
                className="button-form button-form-Submit"
                onClick={handleCreateEditPart}
                loading={isLoadingPart || isLoadingEdit}
              >
                <span></span>
                {location.pathname?.includes('edit') ? 'OK' : '追加'}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default PartCreateEditComponent;

const AddPedestal = ({ setIsAddPedestal, beforeUpload, index, categoryCurrent }: any) => {
  const [step, setStep] = useState<number>(1);
  const [panel, setPanel] = useState<any>();
  const [svgContent, setSvgContent] = useState<any>();
  const [svgPanel, setSvgPanel] = useState<any>();

  useEffect(() => {
    if (panel?.file_path_url) {
      getElementSvgFile(panel?.file_path_url).then((res: any) => {
        setSvgContent({
          ...res,
        });
      });
    } else {
      setSvgContent({
        ...panel,
      });
    }
  }, [panel, panel?.file_path_url]);

  const handleSetFeature = () => {
    setIsAddPedestal(false);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1AddPanel panel={panel} setPanel={setPanel} setStep={setStep} handleSetFeature={handleSetFeature} />
        );
      case 2:
        return <Step2AddPanel svgContent={svgContent} setStep={setStep} setSvgPanel={setSvgPanel} />;
      case 3:
        return (
          <Step3AddHole
            setStep={setStep}
            svgPanel={svgPanel}
            handleSetFeature={handleSetFeature}
            beforeUpload={beforeUpload}
            categoryCurrent={categoryCurrent}
            index={index}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={stylesAddPanel.wrapRenderStep} style={{ background: 'white', padding: '10px' }}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      {renderStep()}
    </div>
  );
};

function Step1AddPanel({ panel, setPanel, setStep, handleSetFeature }: any) {
  const { data } = useFetchParts([4]);

  return (
    <div className={stylesAddPanel.step1}>
      <div className={stylesAddPanel.step1}>
        <div className={stylesAddPanel.wrapChooseStamp}>
          <div
            className={stylesAddPanel.img}
            style={{
              width: WIDTH_STAGE,
              height: HEIGHT_STAGE,
            }}
          >
            {panel?.file_path_url && <img src={panel?.file_path_url} alt="stamp" style={{ width: 120, height: 120 }} />}
          </div>

          <div
            className={stylesAddPanel.listStamp}
            style={{
              height: HEIGHT_STAGE,
            }}
          >
            {data?.data[4]?.map((item: any) => {
              return (
                <div className={stylesAddPanel.panelType} key={item?.name + 'panelItem'}>
                  <p className={stylesAddPanel.title}>{item?.name}</p>

                  <div className={stylesAddPanel.panels} id="panels">
                    {item?.parts?.map((i: any) => {
                      return (
                        <div
                          className={`${stylesAddPanel.panelItem} ${
                            i.id === panel?.id ? stylesAddPanel.panelItemBorder : ''
                          }`}
                          key={i.key}
                          onClick={() => {
                            setPanel(i);
                          }}
                        >
                          <img src={i?.file_path_url} alt={i?.file_name} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center gap-2">
          <Button
            className="button-form button-form-Submit !w-auto"
            onClick={() => {
              handleSetFeature();
            }}
          >
            {'<<詳細画面へ戻る'}
          </Button>
          <Button
            className="button-form button-form-Submit !w-auto"
            disabled={!panel}
            onClick={() => {
              setStep((prev: number) => prev + 1);
            }}
          >
            {'次のステップへ>>'}
          </Button>
        </div>
      </div>
    </div>
  );
}

const Step2AddPanel = ({ svgContent, setStep, setSvgPanel }: any) => {
  const [width, setWidth] = useState<any>();
  const [height, setHeight] = useState<any>();
  const [shapeSize, setShapeSize] = useState<any>();
  const [isShowSlider, setIsShowSlider] = useState(false);

  useEffect(() => {
    setWidth(Number(svgContent?.attributes?.width) ?? WIDTH_PANEL_STAMP_ITEM_LINE);
    setHeight(Number(svgContent?.attributes?.height) ?? WIDTH_PANEL_STAMP_ITEM_LINE);
  }, [svgContent]);

  useEffect(() => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });
    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: (WIDTH_STAGE - width) / 2,
        y: (HEIGHT_STAGE - height * (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]))) / 2,
      },
      scale: {
        base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      rotate: {
        a: 0,
        x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
        y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
        baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      outputSvgSize: shapeSize,
    });
    setShapeSize(shapeSize);
  }, [width, svgContent]);

  const onSubmitFinal = () => {
    setSvgPanel({
      cate: 'panel',
      type: 8,
      ...svgContent,
      outlinePadding: 12 / (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
      transform: {
        x: (WIDTH_STAGE - width) / 2,
        y: (HEIGHT_STAGE - height * (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]))) / 2,
      },
      scale: {
        base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      width: width,
      height: height * (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
      rotate: {
        a: 0,
        x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
        y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
        baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      shapeSize: shapeSize,
      strokeLinejoin: false,
    });
  };

  return (
    <>
      <div className={styles.editor}>
        <div
          className={`${styles.stage} !mb-3`}
          style={{
            width: WIDTH_STAGE,
            height: HEIGHT_STAGE,
          }}
        >
          {svgContent && (
            <SvgMerge
              shapeData={[
                {
                  type: 8,
                  ...svgContent,
                  cate: 'panel',
                  outlinePadding: 12 / (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
                  transform: {
                    x: (WIDTH_STAGE - width) / 2,
                    y: (HEIGHT_STAGE - height * (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]))) / 2,
                  },
                  scale: {
                    base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                  },
                  width: width,
                  height: height * (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
                  rotate: {
                    a: 0,
                    x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
                    y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
                    baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                  },
                  shapeSize: shapeSize,
                  strokeLinejoin: false,
                },
              ]}
              outline={{
                showOutline: true,
              }}
            />
          )}
        </div>
      </div>

      {isShowSlider && (
        <div className="my-react-add-text flex justify-center mb-2">
          <div className="w-[500px]">
            <div className={stylesAddPanel.content}>
              <p className={stylesAddPanel.scaleTitle}>拡大・縮小</p>

              <div className={stylesAddPanel.scale}>
                <Slider
                  tooltip={{ formatter: null }}
                  defaultValue={width}
                  min={Number(svgContent?.attributes?.width) - PANEL_RANGE_SLIDER}
                  max={Number(svgContent?.attributes?.width) + PANEL_RANGE_SLIDER}
                  step={RANGE_SLIDER_STEP_SIZE}
                  onChange={(e) => {
                    setWidth(Number(e));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <MenuBottom
        list={MenuStep2AddPanel({
          onClickEnlarge: () => setIsShowSlider(true),
        })}
      />

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setStep((prev: number) => prev - 1);
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setStep((prev: number) => prev + 1);
            onSubmitFinal();
          }}
          htmlType="button"
        >
          {'次のステップへ>>'}
        </Button>
      </div>
    </>
  );
};

function Step3AddHole({ setStep, svgPanel, handleSetFeature, beforeUpload, categoryCurrent, index }: any) {
  const [socketSpec, setSocketSpec] = useState<any>();
  const ratio_socket_panel = socketSpec?.height / svgPanel?.height;

  const generateImageFromLayer1 = () => {
    const baseSvg: any = document.getElementById('Layer_1');
    if (!baseSvg) {
      console.error('Layer_1 not found!');
      return;
    }
    const svgString = new XMLSerializer().serializeToString(baseSvg);
    const base64 = btoa(svgString);

    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width * 4;
      canvas.height = img.height * 4;

      if (ctx) {
        ctx.scale(4, 4);
        ctx.drawImage(img, 0, 0);
      }

      const dataURL = canvas.toDataURL('image/png');
      cropVisibleArea(dataURL, async (imageBase64Data: any) => {
        const response = await fetch(imageBase64Data);
        const blob = await response.blob();
        const file = new File([blob], 'image.png', { type: blob.type });

        await beforeUpload(file, index, categoryCurrent, ratio_socket_panel, socketSpec?.widthMM);
      });
    };

    img.src = `data:image/svg+xml;charset=utf-8;base64,${base64}`;

    handleSetFeature();
  };

  return (
    <div className={stylesAddSocket.step1}>
      <div className={stylesAddSocket.socketType} key={'socketItem'}>
        <p className={stylesAddSocket.title}>アクリルスタンド差込み口</p>

        <div className={stylesAddPanel.wrapChooseStamp}>
          <div className={styles.editor}>
            <div
              className={`${styles.stage} !mb-3`}
              style={{
                width: WIDTH_STAGE,
                height: HEIGHT_STAGE,
              }}
            >
              <SvgMerge
                shapeData={[
                  svgPanel,
                  {
                    ...socketSpec,
                    cate: 'socket',
                    outlinePadding: 0,
                    scale: {
                      base: 1,
                    },
                    width: socketSpec?.width,
                    x: (WIDTH_STAGE - socketSpec?.width) / 2,
                    y: (HEIGHT_STAGE - socketSpec?.height) / 2,
                  },
                ]}
                outline={{
                  showOutline: true,
                }}
              />
            </div>
          </div>

          <div className={stylesAddSocket.sockets}>
            {SOCKET_LIST?.map((i: any, index: number) => {
              return (
                <div
                  className={`${stylesAddSocket.socketItem} ${
                    i.id === socketSpec?.id ? stylesAddSocket.socketItemBorder : ''
                  }`}
                  key={i.key}
                  onClick={() => {
                    const THICKNESS = 3; //set thickness = constant 3
                    setSocketSpec({
                      ...i,
                      height: THICKNESS * 3,
                      width: i?.widthMM * 3,
                    });
                  }}
                >
                  <div>
                    <div className={stylesAddSocket.wrapImage}>
                      <img src={'/socket-spec.svg'} alt="socketSpec" width={i?.widthMM * 1.5} height={45} />
                    </div>
                    <p>内径 {i?.widthMM}mm</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center gap-2 mt-3">
          <Button
            className="button-form button-form-Submit !w-auto"
            onClick={() => {
              setStep((prev: number) => prev - 1);
            }}
          >
            {'<<詳細画面へ戻る'}
          </Button>
          <Button
            className="button-form button-form-Submit !w-auto"
            onClick={() => generateImageFromLayer1()}
            disabled={!socketSpec}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { generateTransformAttributes, getTextMetrics } from '../../utils';

// {
// type: 1,//text
// text: "Text input",
// startX: 60,
// startY: 60, fontSize: '18px', fontWeight: 300, textLineWidth: 1
// fillColor: 'red',
// }
const TextSvg = ({
  shapeInfo,
  outline,
  drawOutline,
  handleClick,
  index,
  isSingleShowMode,
  isRestoreMode,
  whiteBackground,
  isEndPhase,
  ...props
}: any) => {
  const widthViewBox = Number(shapeInfo?.attributes?.width);
  const heightViewBox = Number(shapeInfo?.attributes?.height);

  const filterAttributes = (attributes: any) => {
    const filteredAttributes = { ...attributes };
    if (filteredAttributes.style) {
      delete filteredAttributes.style;
    }
    return filteredAttributes;
  };

  const renderChild = (child: any) => {
    if (child.children.length > 0) {
      return <>{child.children.map((nestedChild: any) => renderChild(nestedChild))}</>;
    } else {
      return (
        <child.tagName {...filterAttributes(child.attributes)} key={child.attributes.id}>
          {child.content && child.content !== '\n' && child.content}
        </child.tagName>
      );
    }
  };

  if (drawOutline) {
    if (shapeInfo?.children) {
      return (
        <g
          id={`pathsImageId`}
          transform={generateTransformAttributes({
            shapeInfo,
            isApplyMatrix: true,
          })}
        >
          {shapeInfo?.children?.map((pathListItem: any) => {
            if (pathListItem.children.length > 0) {
              return (
                <pathListItem.tagName
                  {...filterAttributes(pathListItem.attributes)}
                  key={pathListItem.attributes.id}
                  onClick={handleClick}
                  fill={outline?.paddingColor ?? 'white'}
                  fillRule={shapeInfo?.fillRule}
                  clipRule={shapeInfo?.clipRule}
                  stroke={shapeInfo?.stroke ?? 'black'}
                  stroke-width={shapeInfo?.outlinePadding}
                  strokeLinecap="round"
                  strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
                  opacity="1"
                >
                  {pathListItem.children.map((child: any) => renderChild(child))}
                </pathListItem.tagName>
              );
            } else {
              return (
                <pathListItem.tagName
                  {...filterAttributes(pathListItem.attributes)}
                  key={pathListItem.attributes.id}
                  onClick={handleClick}
                  fill={outline?.paddingColor ?? 'white'}
                  fillRule={shapeInfo?.fillRule}
                  clipRule={shapeInfo?.clipRule}
                  stroke={shapeInfo?.stroke ?? 'black'}
                  stroke-width={shapeInfo?.outlinePadding}
                  strokeLinecap="round"
                  strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
                  opacity="1"
                >
                  {pathListItem.content && pathListItem.content !== '\n' && pathListItem.content}
                </pathListItem.tagName>
              );
            }
          })}
        </g>
      );
    } else {
      return (
        <>
          <text
            id={index}
            onClick={handleClick}
            strokeLinejoin="round"
            letterSpacing={shapeInfo?.letterSpacing ?? '1'}
            fontFamily={shapeInfo?.fontFamily ?? null}
            fontSize={shapeInfo?.fontSize ?? '18px'}
            x={shapeInfo?.startX ?? 0}
            y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
            fontWeight={shapeInfo?.fontWeight ?? 400}
            stroke={isSingleShowMode && whiteBackground ? 'black' : outline?.paddingColor ?? 'white'}
            strokeWidth={shapeInfo?.textLineWidth + shapeInfo?.outlinePadding + 'px' ?? '1px'}
            strokeLinecap="round"
            transform={generateTransformAttributes({
              shapeInfo,
              isApplyMatrix: true,
            })}
          >
            {shapeInfo?.text}
          </text>
          {isEndPhase ? (
            <>
              <text
                id={'clone_' + index}
                onClick={handleClick}
                strokeLinejoin="round"
                letterSpacing={shapeInfo?.letterSpacing ?? '1'}
                fontFamily={shapeInfo?.fontFamily ?? null}
                fontSize={shapeInfo?.fontSize ?? '18px'}
                x={shapeInfo?.startX ?? 0}
                y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
                fontWeight={shapeInfo?.fontWeight ?? 400}
                stroke={isSingleShowMode && whiteBackground ? 'black' : outline?.paddingColor ?? 'white'}
                strokeWidth={((shapeInfo?.textLineWidth + shapeInfo?.outlinePadding) * 3) / 4 + 'px' ?? '1px'}
                strokeLinecap="round"
                transform={generateTransformAttributes({
                  shapeInfo,
                  isApplyMatrix: true,
                })}
              >
                {shapeInfo?.text}
              </text>
              <text
                id={'clone_' + index}
                onClick={handleClick}
                strokeLinejoin="round"
                letterSpacing={shapeInfo?.letterSpacing ?? '1'}
                fontFamily={shapeInfo?.fontFamily ?? null}
                fontSize={shapeInfo?.fontSize ?? '18px'}
                x={shapeInfo?.startX ?? 0}
                y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
                fontWeight={shapeInfo?.fontWeight ?? 400}
                stroke={isSingleShowMode && whiteBackground ? 'black' : outline?.paddingColor ?? 'white'}
                strokeWidth={((shapeInfo?.textLineWidth + shapeInfo?.outlinePadding) * 1) / 3 + 'px' ?? '1px'}
                strokeLinecap="round"
                transform={generateTransformAttributes({
                  shapeInfo,
                  isApplyMatrix: true,
                })}
              >
                {shapeInfo?.text}
              </text>
              <text
                id={'clone_' + index}
                onClick={handleClick}
                strokeLinejoin="round"
                letterSpacing={shapeInfo?.letterSpacing ?? '1'}
                fontFamily={shapeInfo?.fontFamily ?? null}
                fontSize={shapeInfo?.fontSize ?? '18px'}
                x={shapeInfo?.startX ?? 0}
                y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
                fontWeight={shapeInfo?.fontWeight ?? 400}
                stroke={isSingleShowMode && whiteBackground ? 'black' : outline?.paddingColor ?? 'white'}
                strokeWidth={((shapeInfo?.textLineWidth + shapeInfo?.outlinePadding) * 1) / 2 + 'px' ?? '1px'}
                strokeLinecap="round"
                transform={generateTransformAttributes({
                  shapeInfo,
                  isApplyMatrix: true,
                })}
              >
                {shapeInfo?.text}
              </text>
              <text
                id={'clone_' + index}
                onClick={handleClick}
                strokeLinejoin="round"
                letterSpacing={shapeInfo?.letterSpacing ?? '1'}
                fontFamily={shapeInfo?.fontFamily ?? null}
                fontSize={shapeInfo?.fontSize ?? '18px'}
                x={shapeInfo?.startX ?? 0}
                y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
                fontWeight={shapeInfo?.fontWeight ?? 400}
                stroke={isSingleShowMode && whiteBackground ? 'black' : outline?.paddingColor ?? 'white'}
                strokeWidth={((shapeInfo?.textLineWidth + shapeInfo?.outlinePadding) * 1) / 3 + 'px' ?? '1px'}
                strokeLinecap="round"
                transform={generateTransformAttributes({
                  shapeInfo,
                  isApplyMatrix: true,
                })}
              >
                {shapeInfo?.text}
              </text>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  } else {
    return (
      <g mask={'url(#smallmask)'}>
        <g
          mask={
            Array.isArray(shapeInfo?.deletePath) && shapeInfo?.deletePath?.length > 0
              ? `url(#mask_delete_${isSingleShowMode ? '_' + index : index})`
              : ''
          }
        >
          {shapeInfo?.image_url ? (
            <image
              crossOrigin={'anonymous'}
              href={shapeInfo?.image_url}
              id={index}
              onClick={handleClick}
              // fill={outline?.paddingColor ?? 'white'}
              fillRule={shapeInfo?.fillRule}
              clipRule={shapeInfo?.clipRule}
              stroke={shapeInfo?.stroke ?? 'black'}
              strokeWidth={shapeInfo?.outlinePadding}
              strokeLinecap="round"
              strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
              width={shapeInfo?.size_image?.width}
              height={shapeInfo?.size_image?.height}
              transform={generateTransformAttributes({
                shapeInfo,
                isApplyMatrix: true,
                isTransformImage: true,
              })}
            />
          ) : (
            <text
              onClick={handleClick}
              strokeLinejoin="round"
              letterSpacing={shapeInfo?.letterSpacing ?? '1'}
              fontFamily={shapeInfo?.fontFamily ?? null}
              fontSize={shapeInfo?.fontSize ?? '18px'}
              x={shapeInfo?.startX ?? 0}
              y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
              fontWeight={shapeInfo?.fontWeight ?? 400}
              stroke={isSingleShowMode && whiteBackground ? 'black' : shapeInfo?.fillColor ?? 'black'}
              fill={isSingleShowMode && whiteBackground ? 'black' : shapeInfo?.fillColor ?? 'black'}
              strokeWidth={'1px'}
              transform={generateTransformAttributes({
                shapeInfo,
                isApplyMatrix: true,
              })}
            >
              {shapeInfo?.text}
            </text>
          )}
        </g>
        {isRestoreMode || isSingleShowMode ? (
          <g>
            <text
              mask={`url(#mask_restore_${index})`}
              onClick={handleClick}
              strokeLinejoin="round"
              letterSpacing={shapeInfo?.letterSpacing ?? '1'}
              fontFamily={shapeInfo?.fontFamily ?? null}
              fontSize={shapeInfo?.fontSize ?? '18px'}
              x={shapeInfo?.startX ?? 0}
              y={shapeInfo?.startY + shapeInfo?.metric?.actualBoundingBoxAscent ?? 0}
              fontWeight={shapeInfo?.fontWeight ?? 400}
              stroke={isSingleShowMode && whiteBackground ? 'black' : shapeInfo?.fillColor ?? 'black'}
              fill={isSingleShowMode && whiteBackground ? 'black' : shapeInfo?.fillColor ?? 'black'}
              strokeWidth={'1px'}
              transform={generateTransformAttributes({
                shapeInfo,
                isApplyMatrix: true,
              })}
            >
              {shapeInfo?.text}
            </text>
          </g>
        ) : (
          <></>
        )}
      </g>
    );
  }
};

export default TextSvg;

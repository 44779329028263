import styles from './index.module.scss';
import Step1 from './components/Step1';
import { Button } from 'antd';

export default function AddHole({
  handleSetFeature,
  infoGeneralTemplate,
  setEdittingIndex,
}: {
  handleSetFeature: () => void;
  infoGeneralTemplate: any;
  setEdittingIndex: any;
}) {
  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      <Step1 handleSetFeature={handleSetFeature} infoGeneralTemplate={infoGeneralTemplate} setEdittingIndex={setEdittingIndex}/>
    </div>
  );
}

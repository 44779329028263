import React from 'react';
import TinyMaskPath from './TinyMaskPath';
import SmallMaskPath from './SmallMaskPath';
import OutlineSvgPath from './OutlineSvgPath';
import BaseSvgPath from './BaseSvgPath';
import ExternalMaskPath from './ExternalMaskPath';
import { translateDelete, translateRotate, rotateAction } from '../controllers/ActionController';
import { setRotate } from '../controllers/TouchController';
import { generateTransformAttributes } from '../utils';
import { PADDING_SELECT_SVG_OUTLINE } from 'src/assets/dataUI';
import { GenerateOutlineForDragSvg } from '../element/GenerateOutlineForDragSvg';

const MoveablePart = ({
  dataMoveable,
  hiddenIndex,
  outline,
  handleClick,
  handleDelete,
  heightText,
  widthText,
  extraState,
}: any) => {
  if (hiddenIndex > -1) {
    return (
      <g key={hiddenIndex} id={`current_drag_${hiddenIndex}`}>
        <g
          id={'dragging'}
          style={{
            cursor: 'move',
          }}
        >
          <TinyMaskPath shapeData={[dataMoveable]} hiddenIndex={-1} />
          <SmallMaskPath shapeData={[dataMoveable]} hiddenIndex={-1} />
          <OutlineSvgPath
            isDrawInEdit={true}
            shapeData={[dataMoveable]}
            outline={outline}
            hiddenIndex={-1}
            isSocket={['socket'].includes(dataMoveable?.cate)}
          />
          <BaseSvgPath
            shapeData={[dataMoveable]}
            outline={outline}
            handleClick={handleClick}
            hiddenIndex={-1}
            isSocket={['socket'].includes(dataMoveable?.cate)}
            isDrawInEdit={true}
          />
          <ExternalMaskPath shapeData={[dataMoveable]} hiddenIndex={-1} />
          <GenerateOutlineForDragSvg shapeInfo={dataMoveable} />
        </g>

        {dataMoveable && (
          <>
            <g
              transform={generateTransformAttributes({
                shapeInfo: dataMoveable,
                isAddScaleToRotate: false,
                isMoveToDoublePadding: false,
                isNoScale: false,
              })}
              onClick={handleDelete}
            >
              <image
                x={
                  dataMoveable?.shapeSize?.x -
                    dataMoveable?.outlinePadding * 0.5 -
                    (PADDING_SELECT_SVG_OUTLINE + 9) / dataMoveable?.scale?.base ?? 0
                }
                y={
                  dataMoveable?.shapeSize?.y -
                    dataMoveable?.outlinePadding * 0.5 -
                    (PADDING_SELECT_SVG_OUTLINE + 9) / dataMoveable?.scale?.base ?? 0
                }
                href="/delete.svg"
                width={18 / dataMoveable?.scale?.base}
                height={18 / dataMoveable?.scale?.base}
              />
            </g>

            {dataMoveable?.type !== 98 &&
              dataMoveable?.type !== 99 &&
              dataMoveable?.type !== 100 &&
              (dataMoveable?.type === 9 ? (
                <g
                  transform={generateTransformAttributes({
                    shapeInfo: dataMoveable,
                    isAddScaleToRotate: false,
                    isMoveToDoublePadding: false,
                    additionRotate: 180,
                    isNoScale: false,
                  })}
                  id={`rotate_${hiddenIndex}`}
                  onMouseDown={(e) => setRotate(e, extraState, true, dataMoveable)}
                >
                  <image
                    x={
                      dataMoveable?.shapeSize?.x -
                        dataMoveable?.outlinePadding * 0.5 -
                        (PADDING_SELECT_SVG_OUTLINE + 9) / dataMoveable?.scale?.base ?? 0
                    }
                    y={
                      dataMoveable?.shapeSize?.y -
                        dataMoveable?.outlinePadding * 0.5 -
                        (PADDING_SELECT_SVG_OUTLINE + 9) / dataMoveable?.scale?.base ?? 0
                    }
                    href="/rotate.svg"
                    width={18 / dataMoveable?.scale?.base}
                    height={18 / dataMoveable?.scale?.base}
                  />
                </g>
              ) : (
                <g
                  transform={generateTransformAttributes({
                    shapeInfo: dataMoveable,
                    isAddScaleToRotate: false,
                    isMoveToDoublePadding: false,
                    additionRotate: 180,
                    isNoScale: false,
                  })}
                  id={`rotate_${hiddenIndex}`}
                  onMouseDown={(e) => setRotate(e, extraState, true, dataMoveable)}
                >
                  <image
                    x={
                      dataMoveable?.shapeSize?.x -
                        dataMoveable?.outlinePadding * 0.5 -
                        (PADDING_SELECT_SVG_OUTLINE + 9) / dataMoveable?.scale?.base ?? 0
                    }
                    y={
                      dataMoveable?.shapeSize?.y -
                        dataMoveable?.outlinePadding * 0.5 -
                        (PADDING_SELECT_SVG_OUTLINE + 9) / dataMoveable?.scale?.base ?? 0
                    }
                    href="/rotate.svg"
                    width={18 / dataMoveable?.scale?.base}
                    height={18 / dataMoveable?.scale?.base}
                  />
                </g>
              ))}
          </>
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default MoveablePart;

import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Step1 from './components/Step1';
import { cropVisibleArea, getElementSvgFile, nodeToObject } from 'src/utils';
import { Button } from 'antd';
import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';
import { changeColInUri } from '../utils';
import { generateSvgFromUrl } from '../SvgMerge/ImageToSvg';
import { revertPathStringByScale } from '../SvgMerge/utils';

export default function AddSocket({
  handleSetFeature,
  infoGeneralTemplate,
  setEdittingIndex,
}: {
  handleSetFeature: () => void;
  infoGeneralTemplate: any;
  setEdittingIndex: any;
}) {
  const [socket, setSocket] = useState<any>();
  const [svgContent, setSvgContent] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const THICKNESS = 3;

  useEffect(() => {
    if (socket?.file_path_url) {
      if (socket?.file_path_url?.includes('.png')) {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx: any = canvas.getContext('2d');

          setTimeout(() => {
            ctx.drawImage(image, 0, 0);
            const imageBase64 = canvas.toDataURL('image/png');

            cropVisibleArea(
              imageBase64,
              (
                imageBase64Data: any,
                width: any,
                height: any,
                minX: any,
                minY: any,
                baseWidth: any,
                baseHeight: any
              ) => {
                changeColInUri({
                  base64: imageBase64Data,
                  colto: '#ff0000',
                  callBack: (newUrl: any) => {
                    generateSvgFromUrl(newUrl, (svg: any) => {
                      if (svg) {
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');

                        const img = new Image();
                        img.onload = () => {
                          let scaleRatio = 1;

                          if (svgDoc && scaleRatio) {
                            const svgContentData = nodeToObject(svgDoc);
                            let maxTotal = 0;
                            let minTotal = 999;

                            svgContentData?.children[0]?.children.map(function (item: any, index: any) {
                              let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                              let totalColor = 0;
                              if (fillColorPath.length == 3) {
                                totalColor =
                                  Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                              }

                              if (totalColor > maxTotal) {
                                maxTotal = totalColor;
                              }

                              if (totalColor < minTotal) {
                                minTotal = totalColor;
                              }
                              return true;
                            });

                            let maxLengthPath = 0;
                            let filteredData = svgContentData?.children[0]?.children.filter(function (
                              item: any,
                              index: any
                            ) {
                              let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                              let totalColor = 0;
                              if (fillColorPath.length == 3) {
                                totalColor =
                                  Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                              }

                              if (maxTotal - minTotal > 0) {
                                if (totalColor >= maxTotal) {
                                  //remove all thing that nearest to white color
                                  return false;
                                } else {
                                  if (maxLengthPath < item?.attributes?.d?.length) {
                                    maxLengthPath = item?.attributes?.d?.length;
                                  }
                                  return true;
                                }
                              } else {
                                if (maxLengthPath < item?.attributes?.d?.length) {
                                  maxLengthPath = item?.attributes?.d?.length;
                                }
                                return true;
                              }
                            });

                            filteredData = filteredData
                              .filter((item: any, index: any) => {
                                if (maxLengthPath === item?.attributes?.d?.length) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                              .map((pathInfo: any) => {
                                return {
                                  ...pathInfo,
                                  attributes: {
                                    ...pathInfo?.attributes,
                                    d: revertPathStringByScale({
                                      pathString: pathInfo?.attributes?.d,
                                      scale: {
                                        base: 4,
                                      },
                                    }),
                                  },
                                };
                              });

                            let baseTrueRatio =
                              (THICKNESS * HEIGHT_STAGE) /
                              infoGeneralTemplate?.height /
                              ((img?.height / 4) * (socket?.ratio_socket_panel || 9 / 200));

                            setSvgContent({
                              ...svgContent?.children[0],
                              children: filteredData,
                              image_url: imageBase64Data,
                              cate: 'image',
                              outlinePadding: 0,
                              scale: {
                                base: baseTrueRatio,
                              },
                              attributes: {
                                ...svgContent?.children[0]?.attributes,
                                width: (img?.width / 4) * scaleRatio,
                                height: (img?.height / 4) * scaleRatio,
                                viewBox: `0 0 ${(img?.width / 4) * scaleRatio} ${(img?.height / 4) * scaleRatio}`,
                              },
                              width: (img?.width / 4) * scaleRatio,
                              height: (img?.height / 4) * scaleRatio,
                              transform: {
                                x: (WIDTH_STAGE - img?.width / 4) / 2,
                                y: (HEIGHT_STAGE - img?.height / 4) / 2,
                              },
                              original: false,
                              rotate: {
                                a: 0,
                                x: img.width / 8,
                                y: img.height / 8,
                                baseScale: baseTrueRatio,
                              },
                              shapeSize: {
                                x: 0,
                                y: 0,
                                width: img.width / 4,
                                height: img.height / 4,
                              },
                            });

                            setIsLoading(false);
                          }
                        };
                        img.src = imageBase64Data;
                      }
                    });
                  },
                });
              }
            );
          }, 1000);
        };
        image.src = socket?.temporary_url;
      } else {
        getElementSvgFile(socket?.file_path_url).then((res: any) => {
          setSvgContent({
            ...res,
          });
        });
        setIsLoading(false);
      }
    }
  }, [socket?.file_path_url]);

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      <Step1
        socket={socket}
        setSocket={setSocket}
        svgContent={svgContent}
        handleSetFeature={handleSetFeature}
        setSvgContent={setSvgContent}
        infoGeneralTemplate={infoGeneralTemplate}
        setEdittingIndex={setEdittingIndex}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}

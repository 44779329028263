import React from 'react';

const RectangleSvg = ({ shapeInfo, outline, drawOutline, handleClick, index, hiddenIndex, isDrawInEdit }: any) => {
  if (drawOutline) {
    let listPoint: any = [];
    listPoint.push({
      x: shapeInfo?.x - 5,
      y: shapeInfo?.y - 5,
    });

    listPoint.push({
      x: shapeInfo?.x - 5,
      y: shapeInfo?.y - 5 + shapeInfo?.height + 10,
    });

    listPoint.push({
      x: shapeInfo?.x - 5 + shapeInfo?.width + 10,
      y: shapeInfo?.y - 5 + shapeInfo?.height + 10,
    });

    listPoint.push({
      x: shapeInfo?.x - 5 + shapeInfo?.width + 10,
      y: shapeInfo?.y - 5,
    });

    return (
      <>
        {hiddenIndex === -1 && index === 0 && isDrawInEdit ? (
          <path
            d={`M ${listPoint
              .map((pointData: any) => {
                return `${pointData?.x} ${pointData?.y}`;
              })
              .join(' L ')} Z`}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            stroke="black"
            strokeWidth={11}
            fill={'none'}
          />
        ) : (
          <></>
        )}
        <path
          d={`M ${listPoint
            .map((pointData: any) => {
              return `${pointData?.x} ${pointData?.y}`;
            })
            .join(' L ')} Z`}
          transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
          stroke="grey"
          strokeWidth={10}
          fill={hiddenIndex === -1 && index === 0 && isDrawInEdit ? 'none' : 'black'}
        />
      </>
    );
  } else {
    return (
      <g
        id={index}
        onClick={handleClick}
        transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
      >
        <rect
          width={shapeInfo?.width}
          height={shapeInfo?.height}
          x={shapeInfo?.x}
          y={shapeInfo?.y}
          fill={hiddenIndex === -1 && index === 0 && isDrawInEdit ? 'none' : 'none'}
        />
        <image
          x={shapeInfo?.x + (shapeInfo?.outlinePadding ?? 0) - 15}
          y={shapeInfo?.y + (shapeInfo?.outlinePadding ?? 0) - 15}
          width={shapeInfo?.width + 30}
          height={shapeInfo?.height + 30}
          preserveAspectRatio="none"
        />
      </g>
    );
  }
};

export default RectangleSvg;
